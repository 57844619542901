import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Cardboard = () => {
  return (
    <div>
      <Layout>
        <Head title="Cardboard" />
        <h2>Cardboard</h2>
        <p>
          Sharing a series here from 2020, where I saw what symbols I could make
          with black florist tape on cardboard. The backings are actually the
          remains of pads of 11" x 14" bristol paper, so for each one there are
          15 paintings or oil pastel pieces out there! They were displayed at a
          space / venue in Berkeley on Sacramento St in 2020. Let me know what
          connections or associations you have with the symbols.
        </p>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/1.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/2.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/3.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/4.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/5.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/6.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/7.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/8.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/9.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/10.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/11.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/12.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/13.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/14.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/15.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/16.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/17.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/18.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/19.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/20.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/21.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1639888424/cardboard/22.jpg"
          alt="Benji Friedman Berkeley Art project, cardboard background with black florist tape symbols"
        />
        <Link to="/media">
          <h3>Media</h3>
        </Link>
      </Layout>
    </div>
  );
};

export default Cardboard;
